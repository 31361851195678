import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CenterFocusStrongIcon from '@material-ui/icons/CenterFocusStrong';
import './MediaCard.css';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    width: 400
  },
  media: {
    height: 475,
  },
  iconButton: {float:"right"}
});

export default function MediaCard(props) {
  const classes = useStyles();
  return (
    <div style={{display:"grid",gridTemplateColumns: "repeat(3, 1fr)",gap:8}} className="flexDirection">
    {props.wishList.map(data=>{
        return (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          className={classes.media}
          image={data.imgUrl}
          title="wish"
        />
        <IconButton aria-label="delete" className={classes.iconButton} onClick={()=>props.onMaximize(data.imgUrl)}>
          <CenterFocusStrongIcon />
        </IconButton>
        <CardContent>
          {/* <Typography gutterBottom variant="h5" component="h2">
            Lizard
          </Typography> */}
          <Typography variant="body2" color="textSecondary" component="p">
            {data.message}
          </Typography>
        </CardContent>
      </CardActionArea>
      {/* <CardActions> */}
        {/* <Button size="small" color="primary">
          Share
        </Button> */}
        {/* <Button size="small" color="primary">
          Learn More
        </Button> */}
      {/* </CardActions> */}
    </Card>
    );
    })}
  </div>);
}
