import React from 'react';
import ImageVideoGallery from './ImageVideoGallery';

const items = [
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_birthday1.jpeg?alt=media&token=7beb02b9-c819-417f-8dcb-689f466860f9&_gl=1*1v11ugt*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODI0MjIuNTIuMC4w',
    alt: 'Image 1',
  },
//   {
//     type: 'video',
//     url: 'video1.mp4',
//   },
  // {
  //   type: 'video',
  //   url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/VID20231009211135_muted.mp4?alt=media&token=c281f05b-f49d-461d-84f5-ae25fcea7dbc&_gl=1*1krhwui*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5Nzk5NzAwMy4xMi4xLjE2OTc5OTcwMTAuNTMuMC4w'
  // },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_birthday2.jpeg?alt=media&token=ca7a9611-96e9-4258-8fad-f99ad71c10ab&_gl=1*14g96v9*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODI1NTYuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_always_in_hand.jpeg?alt=media&token=11e13ff5-011e-4240-87a9-f918156eaf14&_gl=1*w1fio2*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODI2MzAuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_coverup_tattoo.jpeg?alt=media&token=4d00f6d8-8278-4625-86e7-d8ca1ae34070&_gl=1*1iglg1u*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODI3NzEuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_face_in_bracelet.jpeg?alt=media&token=923828ab-efbb-4179-ada2-7bc3a2ce373c&_gl=1*pyivfz*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODI4NDEuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_heart.jpeg?alt=media&token=d307bb90-9423-49c4-9614-c8626927b315&_gl=1*6d0tpo*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODMwMTUuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_face_in_heart.jpeg?alt=media&token=29885cae-d723-4ac6-a9c6-86435a3af1f7&_gl=1*rxwa72*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODMyMDQuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'video',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_face_in_bracelet_v.mp4?alt=media&token=13868c8b-f272-4766-87d1-8f71dd20fc79&_gl=1*1sz3ckv*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODM0ODUuMTkuMC4w'
  },
  {
    type: 'video',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_face_in_heart_v.mp4?alt=media&token=0f5e5e16-8d51-482a-b2db-f6eddfc4b259&_gl=1*x78q1y*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODM1NTQuNjAuMC4w'
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_parcel_inside1.jpeg?alt=media&token=d3650c74-3f13-4201-b90f-daaec64f98fb&_gl=1*108dm9t*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODM2NDguNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_parcel_inside2.jpeg?alt=media&token=26f7c7e9-b8bf-41b3-9af0-6eff68ffcc0b&_gl=1*1b5u3yg*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODM3MTEuNjAuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/mymon_parcel_inside3.jpeg?alt=media&token=4457288f-4d91-419a-98e8-23e8187e19ff&_gl=1*1hwfyiq*_ga*MTUwODAxNjIxNC4xNjk2NzA2MDM1*_ga_CW55HF8NVT*MTY5ODA4MTAwMC4xMy4xLjE2OTgwODM5MTMuNTUuMC4w',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://images.unsplash.com/photo-1598622443054-499119043e82?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80',
    alt: 'Image 1',
  },
  {
    type: 'image',
    url: 'https://www.birthdaywishes.expert/wp-content/uploads/2018/01/birthday-wishes-on-photo-with-cherry-branch.jpg',
    alt: 'Image 1',
  }
  // Add more items here
];

function Image() {
  return (
    <div style={{marginTop:300,overflowY:"scroll",scrollBehavior:"smooth",height:1000}}>
      <ImageVideoGallery items={items} />
    </div>
  );
}

export default Image;
