import React from 'react';
// import { Carousel } from '@trendyol-js/react-carousel';

function Quotes() {
    return (
        <div style={{backgroundColor:"black"}}>
            {/* <Carousel>
                <div style={{backgroundColor:"white",width:100,height:100}}>test1</div>
                <div  style={{backgroundColor:"white",width:100,height:100}}>test2</div>
                <div  style={{backgroundColor:"white",width:100,height:100}}>test3</div>
            </Carousel> */}
        </div>
    )
}

export default Quotes;
