import React,{ useState,useEffect } from 'react';
import './App.css';
import Swiper from "./components/Swiper";
import Player from './components/Player';
import Fab from '@material-ui/core/Fab';
import MediaCard from './components/MediaCard';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { getFirestore, collection, query, where, getDocs,setDoc,doc } from "firebase/firestore";
import app from './firebase';
import { useContext } from "react";
import { AuthContext } from "./context/AuthContext";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

function App() {
  const [wish, setWish] = useState(true);
  const [open, setOpen] = useState(false);
  const [maxViewUrl, setMaxViewUrl] = useState("");

  useEffect(() => {
    if(!wish){
      // const db = app.firestore();
      // const loginRef = collection(db, "login_details");
      // setDoc(doc(loginRef), {
      //   app_opened_datetime: new Date,
      //   app_open_status: "opened"
      // });
    }
  }, []);

  const handleClickOpen = async(imgUrl) => {
    await setMaxViewUrl(imgUrl);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
  <React.Fragment>
    {/* <Swiper /> */}
    <div className="title"><h2>SatsMon</h2></div>
    <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
      {/* <Player url={"https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/Kaatrin%20Mozhi%20_%20Satheesh_%20FavList(MP3_160K).mp3?alt=media"} /> */}
      <Player url={"https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/birthday%20song%20sep%205.mp3?alt=media"} />  
      <Fab color="primary" aria-label="add" onClick={async()=>{
        const db = getFirestore(app);
        // const loginRef = collection(db, "login_details");
        // const q = query(collection(db, "login_details"));
        // const querySnapshot = await getDocs(q);
        // querySnapshot.forEach((doc) => {
        //   console.log(doc.id, " => ", doc.data());
        // });
        
        setWish(true);
        // await setDoc(doc(loginRef), {
        //   login_datetime: new Date,
        //   wishes_status: "opened"
        // });
      }
        }>
        Wishes
      </Fab>
    </div>
    {(wish)?
    <div>
      <MediaCard wishList={[
      {imgUrl:"https://images.unsplash.com/photo-1598622443054-499119043e82?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80",message:""},
      {imgUrl:"https://askbirthday.com/wp-content/uploads/2018/03/happy-birthday-wiches-2-99-andrea-lauren-flamingoes-illustration-stationery.jpg",message:""},
      {imgUrl:"https://www.birthdaywishes.expert/wp-content/uploads/2018/01/birthday-wishes-on-photo-with-cherry-branch.jpg",message:" இனிய பிறந்த நாள் வாழ்த்துக்கள்..."}
      ]} onMaximize={handleClickOpen}/>
       <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open} style={{width:"100%"}}>
        <DialogTitle id="customized-dialog-title" onClose={handleClose}>
         Happy Birthday
        </DialogTitle>
        <DialogContent style={{maxWidth: "100%"}}>
        <img src={maxViewUrl} style={{width:500,height:500}}></img>
        </DialogContent>
      </Dialog>
    </div>
    :null}
  </React.Fragment>);
}

export default App;
