import React, { useState, useEffect } from 'react';
import './FooterSocial.css';
import simmam_logo from '../../src/img/simmam_logo.jpg';
// import { getFirestore, collection, query, where, getDocs, setDoc, doc, db} from "../firebase";

export default function FooterSocial(props) {
    // const [simmam, setSimmam] = useState(props.configList[0]['simmam']);
    const [updated, setUpdated] = useState(props.configList[0]['last_updated_date']);

    useEffect(() => {
        // getCities(db);
            // const signatureRef = collection(db, "signature_config_values");
            // const q = query(signatureRef);
            // const querySnapshot = getDocs(q);
            // console.log("querySnapshot",querySnapshot.data());
            // querySnapshot.then(data=>{
            //     console.log("querySnapshot",data);
            // }).catch(error=>{
            //     console.log("error",error);
            // });
            // querySnapshot.forEach((doc) => {
            //     console.log(doc.id, " => ", doc.data());
            // });
    }, []);

    return (
        <React.Fragment>
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", fontSize: 14 }}>
                {/* <div style={{ width: 40, height: 40 }}><span className="simmam-span"><img src={simmam_logo} alt="icon" /></span></div> */}
                {/* <div>சிம்மம்:  {simmam}</div> */}
                <div><p>Updated on: {updated}</p></div> <br></br>
                {/* <div><p>By: Satheeeh D</p></div> */}
            </div>
            <div class="center" style={{ bottom: 10 }}>
                
                <div class="icons second" style={{flexDirection:'column',justifyContent:"space-between"}}>
                <div><p style={{color:"green"}}>இனிய பிறந்த நாள் வாழ்த்துக்கள்...</p></div>
                <div><p style={{color:"blue"}}>Satheesh D</p></div>
                    {/* <li class="shadow-2"><a href="https://www.facebook.com/signatureBucketArt/"><span class="fab fa-facebook-f" style={{ color: '#1DA1F2' }}></span></a></li>
                    <li class="shadow-2"><a href="https://www.instagram.com/signature_bucket_art/"><span class="fab fa-instagram" style={{ color: '#E1306C' }}></span></a></li> */}
                </div>
            </div>
        </React.Fragment>
    )
}
