import React, { useState, useEffect } from "react";
import Fab from '@material-ui/core/Fab';
import { getFirestore, collection, query, where, getDocs,setDoc,doc } from "firebase/firestore";
import app from '../firebase';

const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = async() => {
    const db = getFirestore(app);
    // const loginRef = collection(db, "login_details");
    // await setDoc(doc(loginRef), {
    //   login_datetime: new Date,
    //   listen_status: "song listened" 
    // });
    setPlaying(!playing);
  };

  useEffect(() => {
      playing ? audio.play() : audio.pause();
    },
    [playing]
  );

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, []);

  return [playing, toggle];
};

const Player = ({ url }) => {
  const [playing, toggle] = useAudio(url);

  return (
    <div>
      <Fab color="primary" aria-label="add" onClick={toggle}>
        {playing ? "Pause" : "Song"}
      </Fab>
    </div>
  );
};

export default Player;
