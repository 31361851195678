import React, { useState, useEffect } from 'react';
import './HomePage.css';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import FooterSocial from './FooterSocial';
import { getAuth, signInWithPhoneNumber, RecaptchaVerifier } from "firebase/auth";
import { getFirestore, collection, query, where, getDocs, setDoc, doc, db} from "../firebase";
import under_maintenance_compressed from '../../src/img/under_maintenance_compressed.jpg';
import App from '../App';
import ChatApp from '../chat/ChatApp';
import Image from '../version3/Image';

export default function HomePage() {
    const [otpVerifyPage, setOtpVerifyPage] = useState(false);
    const [mainPage, setMainPage] = useState(false);
    const [mobileNumber, setMobileNumber] = useState("");
    const [otpCode, setOtpCode] = useState("");
    const [error, setError] = useState("");
    const [configList, setConfigList] = useState([]);
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [verifyBtnDisable, setVerifyBtnDisable] = useState(false);

    useEffect(async() => {
        const auth = getAuth();
        window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
            'size': 'invisible',
            'callback': (response) => { }
        }, auth);
        // const loginRef = collection(db, "login_details");
        // setDoc(doc(loginRef), {
        //     app_opened_datetime: new Date,
        //     app_open_status: "opened"
        // });
        const configValues = collection(db, 'signature_config_values');
        const configSnapshot = await getDocs(configValues);
        const configList = configSnapshot.docs.map(doc => doc.data());
        setConfigList(configList);
    }, []);

    const generateOtp = () => {
        if (mobileNumber === "9659363434" || mobileNumber === "9176242049") {
            setButtonDisabled(true);
            let number = '+91' + mobileNumber;
            const auth = getAuth();
            const appVerifier = window.recaptchaVerifier;
            signInWithPhoneNumber(auth, number, appVerifier)
                .then((confirmationResult) => {
                    window.confirmationResult = confirmationResult;
                    const otpRef = collection(db, "otp_audit_log");
                    setDoc(doc(otpRef), {
                        mobileNumber: mobileNumber,
                        status: "success",
                        datetime: new Date
                    });
                    setOtpVerifyPage(true);
                    // const customSmsMessage = 'SATS SMS message here'; // Customize the SMS content
                    // return window.confirmationResult.confirm(customSmsMessage);
                }).catch((error) => {
                    console.log(error);
                    setError(error.message);
                    // setError("Failed due to technical error. Try after 15 mins.");
                    const otpRef = collection(db, "otp_audit_log");
                    setDoc(doc(otpRef), {
                        mobileNumber: mobileNumber,
                        status: "Tech Error",
                        datetime: new Date
                    });
                });
        } else {
            setError("Unauthorised access mobile number.");
            const otpRef = collection(db, "otp_audit_log");
            setDoc(doc(otpRef), {
                mobileNumber: mobileNumber,
                status: "Unauthorised access",
                datetime: new Date
            });
        }
    }

    const verifyOtp = () => {
        setVerifyBtnDisable(true);
        window.confirmationResult.confirm(otpCode).then((result) => {
            const user = result.user;
            setMainPage(true);
            const otpRef = collection(db, "otp_audit_log");
            setDoc(doc(otpRef), {
                main_page_opened: "opened",
                otp_status: "SUCCESS",
                datetime: new Date
            });
        }).catch((error) => {
            console.log(error);
            setError("Invalid OTP.");
            const otpRef = collection(db, "otp_audit_log");
            setDoc(doc(otpRef), {
                main_page_opened: "not_opened",
                otp_status: "Invalid OTP",
                datetime: new Date
            });
        });
    }

    const onChangeMobileNumber = (event) => {
        let isValid = true;
        if (typeof event.target.value !== "undefined") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(event.target.value)) {
                isValid = false;
            } else if (event.target.value.length > 10) {
                isValid = false;
            }
        }
        if (isValid || event.target.value === "") {
            setMobileNumber(event.target.value);
            setError("");
        }
    }

    const onChangeOtpCode = (event) => {
        let isValid = true;
        if (typeof event.target.value !== "undefined") {
            var pattern = new RegExp(/^[0-9\b]+$/);
            if (!pattern.test(event.target.value)) {
                isValid = false;
            } else if (event.target.value.length > 6) {
                isValid = false;
            }
        }
        if (isValid || event.target.value === "") {
            setOtpCode(event.target.value);
            setError("");
        }
    }

    return (
        (!mainPage) ?
        <div class="login-div">
           
                <React.Fragment>
                    <div class="logo">
                        <span class="logo-span"> <img src="https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/kids2%20(1).png?alt=media&token=2622cc53-f3ce-41fd-a66f-78c73dbfe4e8&_gl=1*1nuzogd*_ga*MjAwMDUyNzY5Mi4xNjkwMDMyNDU5*_ga_CW55HF8NVT*MTY5NjI2MDQyNS42LjEuMTY5NjI2MDU2My40MC4wLjA." alt="Signature Bucket Art" /></span>
                    </div>
                    <div class="logintitle">SatsMon</div>
                    <div class="sub-title">LIFE</div>
                    <div class="fields">
                        {(!otpVerifyPage) ?
                            <React.Fragment>
                                <div class="username"><input type="username" class="user-input" placeholder="Enter Mobile Number" value={mobileNumber} onChange={(event) => onChangeMobileNumber(event)} /></div>
                                <p style={{ color: "red" }}>{error}</p>
                                <button class="signin-button" onClick={() => generateOtp()}
                                    disabled={(mobileNumber.length === 10) ? false : true}>{(isButtonDisabled)?"Loading...":"GET OTP"}</button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                                <div class="username"><input type="username" class="user-input" placeholder="Enter OTP Number" value={otpCode} onChange={(event) => onChangeOtpCode(event)} /></div>
                                <p style={{ color: "red" }}>{error}</p>
                                <button class="signin-button" onClick={() => verifyOtp()}
                                    disabled={(otpCode.length === 6) ? false : true}>{(verifyBtnDisable)?"Loading...":"VERIFY OTP"}</button>
                            </React.Fragment>
                        }
                    </div>
                    {(configList.length>0) ? <FooterSocial configList={configList}/> : null}
                </React.Fragment>
                {/* : <React.Fragment> */}
                    {/* <p style={{ color: 'blue' }} class="sub-title">UNDER DEVELOPMENT. <br /> Only one person can able to reach this page.</p>
                    <img style={{ width: "100%", height: "100%", borderRadius: 20 }}
                        src={under_maintenance_compressed} alt="Signature Bucket Art" /> */}
                        {/* <App /> */}
                        {/* <ChatApp/> */}
                         {/* <Image/>
                 </React.Fragment>} */}
            <div id="recaptcha-container" style={{ display: "none" }}></div>
        </div> : <Image/>
    )
}

{/* <div class="username">
                    <PhoneInput
                        country={'in'}
                        value={"+919659363434"}
                        // onChange={()=>alert("test")}
                        inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true
                        }}
                    /></div> */}

{/* <div class="link">
                <a href="#">Forgot password?</a> or <a href="#">Sign up</a>
            </div> */}
