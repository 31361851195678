import React, { useState, useEffect } from 'react';
import './ImageVideoGallery.css';

const itemsPerRow = 3; // Number of items per row

const ImageVideoGallery = ({ items }) => {
  const [displayedItems, setDisplayedItems] = useState([]);
  const [maximizedItem, setMaximizedItem] = useState(null);

  useEffect(() => {
    // Split items into rows
    const rows = [];
    for (let i = 0; i < items.length; i += itemsPerRow) {
      rows.push(items.slice(i, i + itemsPerRow));
    }
    setDisplayedItems(rows);
  }, [items]);

  const handleMaximize = (item) => {
    setMaximizedItem(item);
  };

  const handleMinimize = () => {
    setMaximizedItem(null);
  };

  return (
    <div className="gallery-container">
      {maximizedItem && (
        <div className="maximized-overlay" onClick={handleMinimize}>
          <div className="maximized-content">
            {maximizedItem.type === 'image' ? (
              <img
                src={maximizedItem.url}
                alt={maximizedItem.alt}
                className="zoomable"
                onClick={handleMinimize}
              />
            ) : (
              <video controls>
                <source src={maximizedItem.url} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )}
          </div>
        </div>
      )}

      <div className="gallery">
        {displayedItems.map((row, rowIndex) => (
          <div key={rowIndex} className="gallery-row">
            {row.map((item, itemIndex) => (
              <div key={itemIndex} className="gallery-item">
                {item.type === 'image' ? (
                  <img
                    src={item.url}
                    alt={item.alt}
                    onClick={() => handleMaximize(item)}
                    className="zoomable"
                  />
                ) : (
                  <video controls>
                    <source src={item.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default ImageVideoGallery;
