import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
// import './index.less';
import HomePage from './version2/HomePage';
import Quotes from './version2/quotes/Quotes';
import ChatApp from './chat/ChatApp';
import { AuthContextProvider } from "./context/AuthContext";
import { ChatContextProvider } from "./context/ChatContext";
import Image from './version3/Image';

ReactDOM.render(
  <React.StrictMode>
    <AuthContextProvider>
      <ChatContextProvider>
        {/* <ChatApp /> */}
        {/* <App /> */}
        <HomePage />
        {/* <Quotes /> */}
        {/* <Image/> */}
      </ChatContextProvider>
    </AuthContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);


