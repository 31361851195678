import React,{ useState } from "react";
import Swiper from "react-id-swiper";
import { compose, pure, withProps, withHandlers } from "recompose";
import Item from "./Item";
import Player from './Player';
import Fab from '@material-ui/core/Fab';

let data = [];
for(let i=1; i< 49;i++){
  if(i!==29){
    data.push({
      name: `SignatureBucketArt${i}`,
      // link: `https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/${i}.jpg?alt=media`
      link: `/img/${i}.jpg`
    });
  }
}

const updateHomeData = () => {                              
  let homeData = [];
  for(let i=1; i< 28;i++){
    if(i!==3){
    homeData.push({
      name: `SignatureBucketArt${i}`,
      // link: `https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/${i}.jpg?alt=media`
      link: `/img/home/kivi_home_${i}.jpg`
    });
  }
    data = homeData;
  }
}

const SwiperApp = ({
  data,
  params,
  renderCompositeComponent
}) => {
  const [homeDatas, setHomeDatas] = useState(data);
  return (
    <div className="container">
      <div className="title"><h2>Signature Bucket Art</h2></div>
      <div style={{display:"grid",placeItems:"center"}}>
      <div style={{display:"flex"}}>
        <Player url={"https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/Kaatrin%20Mozhi%20_%20Satheesh_%20FavList(MP3_160K).mp3?alt=media"} />
        <Fab color="primary" aria-label="add" onClick={()=>{
          // updateHomeData();
          let homeData = [];
          for(let i=1; i< 28;i++){
            if(i!==3){
            homeData.push({
              name: `SignatureBucketArt${i}`,
              // link: `https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/${i}.jpg?alt=media`
              link: `/img/home/kivi_home_${i}.jpg`
            });
          }
          }
          setHomeDatas(homeData);
        }}>
          Home
        </Fab>
        </div>
      </div>
      <Swiper {...params}>{homeDatas.map(renderCompositeComponent)}</Swiper>
    </div>
  )};

export default compose(
  withProps(() => {
    const params = {
      effect: "coverflow",
      loop: true,
      centeredSlides: true,
      slidesPerView: 3,
      grabCursor: true,
      autoplay: {
        delay: 1500,
        disableOnInteraction: false
      },
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
      rebuildOnUpdate: true
    };

    // let data = [
    //   {
    //     name: "Google",
    //     link: "https://firebasestorage.googleapis.com/v0/b/signature-bucket-art.appspot.com/o/48.jpg?alt=media"
    //   },
    //   {
    //     name: "Amazon",
    //     link: "https://picsum.photos/id/2/400/400"
    //   },
    //   {
    //     name: "Facebook",
    //     link: "https://picsum.photos/id/3/400/400"
    //   },
    //   {
    //     name: "Yahoo",
    //     link: "https://picsum.photos/id/4/400/400"
    //   },
    //   {
    //     name: "Mircrosoft",
    //     link: "https://picsum.photos/id/5/400/400"
    //   },
    //   {
    //     name: "Apple",
    //     link: "https://picsum.photos/id/1/400/400"
    //   }
    // ];

    return {
      params,
      data
    };
  }),
  withHandlers({
    handleClick: () => (link) => () => {
      console.log(link);
    }
  }),
  withHandlers({
    renderCompositeComponent: ({ handleClick }) => (item, index) => (
      <Item handleClick={handleClick(item.link)} {...item} key={index}/>
    )
  }),
  pure
)(SwiperApp);
