import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs, doc, addDoc, setDoc, updateDoc, connectFirestoreEmulator, query } from 'firebase/firestore';
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

let configKey = {
  apiKey: "AIzaSyC7r6_77I8oyidhSXSFumlHB-QAEtN6fK4",
  authDomain: "signature-bucket-art.firebaseapp.com",
  databaseURL: "https://signature-bucket-art.firebaseio.com",
  projectId: "signature-bucket-art",
  storageBucket: "signature-bucket-art.appspot.com",
  messagingSenderId: "576739263340",
  appId: "1:576739263340:web:8475ac8c433e4ebc156949",
  measurementId: "G-J8100TLP1V"
}

const app = initializeApp(configKey);
const db = getFirestore(app);
export const storage = getStorage();
export const auth = getAuth();
// if (window.location.hostname.includes("localhost")) {
//   connectFirestoreEmulator(db, 'localhost', 8080);
// }
export default app;

export { getFirestore, collection, getDocs, doc, addDoc, setDoc, updateDoc, query , db};